

 .boton {
	background-color: var(--btn);
	border: 2px solid #fff;
	border-radius: 90px;
	box-shadow: var(--btn-hover) 6px 6px 0 0;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	font-size: 18px;
	padding: 0 18px;
	text-align: center;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}
.boton:hover {
	background-color: white;
	color: var(--btn);
	border: 2px solid var(--btn);
  }
  
  
  @media (min-width: 768px) {
	.boton {
	  min-width: 120px;
	  padding: 0 25px;
	}
  }

/*
.boton span {
	position: relative;
	z-index: 2;
	transition: .3s ease all;
}



.boton.one::after {
	content: "";
	width: 100%;
	height: 300px;
	background: var(--btn-hover);
	position: absolute;
	z-index: 1;
	top: -300px;
	left: 0;
	transition: .6s ease-in-out all;
	border-radius: 0px 0px 300px 300px;
}

.boton.one:hover::after {
	top: 0;
}



.boton.two::after {
	content: "";
	width: 100%;
	height: 100%;
	background: var(--btn-hover);
	position: absolute;
	z-index: 1;
	top: -80px;
	left: 0;
	transition: .6s ease-in-out all;
}

.boton.two:hover::after {
	top: 0;
}



.boton.three::after {
	content: "";
	width: 1px;
	height: 1px;
	background: none;
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	transition: .9s ease-in-out all;
	border-radius: 100px;
	transform-origin: center;
}

.boton.three:hover::after {
	transform: scale(400);
	background: var(--btn-hover);
} */

/*boton del formulario */

.formContent__btn{
	display: inline-flex !important;
	align-items: center !important;
	justify-content: center !important;
	background: var(--btn) !important;
	color: #fff !important;
	font-weight: 500 !important;
	border: none !important;
	cursor: pointer !important;
	text-transform: capitalize !important;
	transition: .5s ease all !important;
	position: relative !important;
	overflow: hidden !important;
	width: auto !important;
	padding: 16px 24px !important;
	display: flex !important;
	justify-content: flex-start !important;
}
